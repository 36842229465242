<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="RIGHT REQUEST"
        :filter="filter"
        placeholder="Search Name-Surname, IP Address"
        :hasDropdown="false"
        @sidebar="openFilter"
        :hasExport="true"
        @search="handleSearch"
        @export="exportExcel"
      />

      <b-sidebar
        id="sidebar-1"
        v-model="isShowSidebar"
        title="FILTER"
        aria-labelledby="sidebar-no-header-title"
        no-header
        backdrop
        shadow
        right
        ref="sidebarFilter"
        width="85vw"
      >
        <div class="px-3">
          <b-row class="header-filter">
            <b-col class="text-filter"> Filter </b-col>
            <b-col class="text-right">
              <button class="btn-clear" @click.prevent="clearFilter">
                x <span class="text-under">Clear Filter</span>
              </button>
            </b-col>
          </b-row>
          <p class="font-weight-bold mt-3 mb-2 p-black">Search</p>
          <b-input-group>
            <b-form-input
              type="search"
              class="search-bar"
              placeholder="Search Request Number, Tel."
              v-model="filter.search"
            >
            </b-form-input>
          </b-input-group>
        </div>
        <div class="px-3">
          <div class="mt-3">
            <div class="font-weight-bold mb-2">Valid From</div>
            <div :class="['input-container']">
              <datetime
                :input-style="styleDatetime"
                v-model="filter.start_date"
                placeholder="Please Select Date"
                @close="changeStartDate"
                ref="startDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.startDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="font-weight-bold mb-2">Valid To</div>
            <div :class="['input-container']">
              <datetime
                :input-style="styleDatetime"
                v-model="filter.end_date"
                placeholder="Please Select Date"
                :min-datetime="filter.start_date"
                @close="onChangeEndDate"
                ref="endDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.endDate.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="font-weight-bold mb-2">Request Type</div>
            <b-form-group>
              <div>
                <b-form-checkbox
                  class="w-50"
                  v-model="selectAllCb"
                  @change="selectAllStatus($event)"
                  >All</b-form-checkbox
                >
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  class="ml-3"
                  v-model="filter.request_type"
                  :options="requestTypeOption"
                  stacked
                ></b-form-checkbox-group>
              </div>
            </b-form-group>
          </div>
        </div>
        <template #footer>
          <div class="d-flex justify-content-between shadow align-items-center">
            <div class="w-100">
              <b-button
                block
                variant="dark"
                class="text-light rounded-pill"
                @click="hide"
                >Cancel</b-button
              >
            </div>
            <div class="w-100">
              <b-button
                block
                variant="light"
                id="handle-search-filter"
                class="text-body rounded-pill btn-main"
                @click="handleSearch"
              >
                Search
              </b-button>
            </div>
          </div>
        </template>
      </b-sidebar>
      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-container class="no-gutters bg-white p-0">
          <b-tabs v-model="filter.status">
            <b-tab :title="`All (${items.total_count})`"> </b-tab>
            <b-tab :title="`Open (${items.count_open_tab})`"> </b-tab>
            <b-tab :title="`Success (${items.count_success_tab})`"> </b-tab>
            <b-tab :title="`Cancel (${items.count_cancel_tab})`"> </b-tab>
          </b-tabs>
        </b-container>
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items.data"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(first_name_th)="data">
                <div class="text-left">
                  <div v-if="data.item.first_name_th">
                    {{ data.item.first_name_th }} {{ data.item.last_name_th }}
                  </div>
                  <div v-else>-</div>
                  <div
                    class="badge badge-danger"
                    v-if="
                      data.item.request_type_id == 6 &&
                      data.item.request_status_id == 2
                    "
                  >
                    บัญชีถูกลบ
                  </div>
                </div>
              </template>
              <template v-slot:cell(telephone)="data">
                <div class="text-left">
                  <div v-if="data.item.telephone">
                    <p class="mb-1">
                      {{ convertTelePhone(data.item.telephone) }}
                    </p>
                    <span>{{ data.item.email }}</span>
                  </div>
                  <div v-else>-</div>
                </div>
              </template>
              <template v-slot:cell(created_time)="data">
                <div v-if="data.item.created_time">
                  <div>
                    {{ data.item.created_time | moment($formatDateNew) }}
                  </div>
                  <div class="time-color">
                    {{ data.item.created_time | moment("HH:mm:ss") }}
                  </div>
                </div>
                <div v-else>-</div>
              </template>
              <template v-slot:cell(request_status_id)="data">
                <div
                  :class="
                    data.item.request_status_id == 1
                      ? ''
                      : data.item.request_status_id == 2
                      ? 'text-success'
                      : 'text-error'
                  "
                >
                  {{
                    data.item.request_status_id == 1
                      ? "Open"
                      : data.item.request_status_id == 2
                      ? "Success"
                      : "Cancel"
                  }}
                </div>
              </template>
              <template v-slot:cell(manage)="data">
                <b-button
                  variant="link"
                  class="px-1 py-0 link-btn"
                  @click="gotoDetail(data.item)"
                  :disabled="
                    data.item.request_type_id == 6 &&
                    data.item.request_status_id == 2
                  "
                >
                  <u>Detail</u>
                </b-button>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="form-inline justify-content-start">
            <p class="mb-0 p-gray">
              Showing
              <span v-if="items.length === 0" id="from-row">0</span>
              <span v-else id="from-row">{{
                (filter.page - 1) * filter.take + 1
              }}</span>
              to
              <span id="to-row">
                {{
                  filter.take * filter.page > rows
                    ? rows
                    : filter.take * filter.page
                }}</span
              >
              of <span id="total-row">{{ rows }}</span> entries
            </p>
          </b-col>
          <b-col cols="12" md="6" class="form-inline justify-content-end">
            <b-pagination
              v-model="filter.page"
              :total-rows="rows"
              :per-page="filter.take"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              class="m-md-0"
              @change="pagination"
            ></b-pagination>
            <b-form-select
              class="ml-2"
              v-model="filter.take"
              @change="handleChangeTake"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
        <ModalAlertError ref="modalAlertError" :text="modalMessage" />
      </div>
    </div>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import OtherLoading from "@/components/loading/OtherLoading";
import ModalAlertError from "@/components/modal/ModalAlertError";
export default {
  components: {
    OtherLoading,
    ModalAlertError,
  },
  data() {
    return {
      isLoading: true,
      filter: {
        search: "",
        page: 1,
        take: 10,
        start_date: "",
        endDate: "",
        request_type: [1, 2, 3, 4, 5, 6],
        status: 0,
      },
      requestTypeOption: [
        { text: "Access to information", value: 1 },
        { text: "Send information", value: 2 },
        { text: "Withdraw the information", value: 3 },
        { text: "Request correction", value: 4 },
        { text: "Suspend, object to the use of information", value: 5 },
        { text: "Request deletion", value: 6 },
      ],
      rows: 0,
      showing: 1,
      showingTo: 10,
      items: {},
      fields: [
        {
          key: "request_id",
          label: "Request Number",
          class: "text-nowrap",
          thStyle: {
            width: "10%",
          },
        },
        {
          key: "first_name_th",
          label: "Name-Surname",
          class: "text-nowrap",
          thStyle: {
            width: "20%",
          },
        },
        {
          key: "telephone",
          label: "Tel / Email",
          class: "text-nowrap",
          thStyle: {
            width: "20%",
          },
        },
        {
          key: "request_name",
          label: "Request Name",
          class: "text-nowrap",
          thStyle: {
            width: "15%",
          },
        },
        {
          key: "created_time",
          label: "Date / Time",
          class: "text-nowrap",
          thStyle: {
            width: "15%",
          },
        },
        {
          key: "request_status_id",
          label: "Status",
          class: "text-nowrap",
          thStyle: {
            width: "10%",
          },
        },
        {
          key: "manage",
          label: "Detail",
          class: "text-nowrap",
          thStyle: {
            width: "10%",
          },
        },
      ],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      selectAllCb: true,
      isBusy: false,
      isShowSidebar: false,
      modalMessage: "",
    };
  },
  watch: {
    "filter.status"(val) {
      this.pagination(1);
    },
    "filter.request_type"(val) {
      if (val.length == 6) {
        this.selectAllCb = true;
      } else if (val.length == 0) {
        this.filter.request_type = [1];
      } else {
        this.selectAllCb = false;
      }
    },
  },
  async created() {
    await this.getList();
    this.isLoading = false;
  },
  methods: {
    openFilter() {
      this.isShowSidebar = true;
    },
    async getList() {
      this.isBusy = true;
      await this.$store.dispatch("getAllRightRequestList", this.filter);
      let data = this.$store.state.allRightRequest.allRightRequestList;
      if (data.result == 1) {
        this.items = data.detail;
        this.rows =
          this.filter.status == 0
            ? data.detail.total_count
            : this.filter.status == 1
            ? data.detail.count_open_tab
            : this.filter.status == 2
            ? data.detail.count_success_tab
            : data.detail.count_cancel_tab;
        this.isBusy = false;
      }
    },
    hide() {
      this.isShowSidebar = false;
    },
    async handleSearch(e) {
      this.filter.page = 1;
      this.showing = 1;
      this.showingTo = this.filter.take;
      this.hide();
      this.getList();
    },
    clearFilter() {
      this.filter = {
        search: "",
        page: 1,
        take: 10,
        start_date: "",
        endDate: "",
        request_type: [1, 2, 3, 4, 5, 6],
      };
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
      }
      this.getList();
    },
    handleChangeTake(take) {
      this.filter.page = 1;
      this.filter.take = take;
      this.showingTo = this.filter.take;
      this.getList();
    },
    changeStartDate() {
      if (!this.filter.end_date) {
        this.filter.end_date = this.filter.start_date;
      }
      if (this.filter.start_date >= this.filter.end_date) {
        this.filter.end_date = moment(this.filter.start_date)
          .add("day", 0)
          .format();
      }
    },
    onChangeEndDate() {
      if (!this.filter.start_date) {
        this.filter.start_date = this.filter.end_date;
      }
    },
    selectAllStatus(value) {
      if (value) {
        this.filter.request_type = [1, 2, 3, 4, 5, 6];
      } else {
        this.filter.request_type = [1];
      }
    },
    async gotoDetail(data) {
      this.$router.push({ path: `/allrightrequest/detail/${data.request_id}` });
    },
    async exportExcel() {
      await this.$store.dispatch("exportAllRightRequest");
      let data = this.$store.state.allRightRequest.respExport;
      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `AllRightRequestLog-` + dateExcel + `.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .nav-tabs .nav-link:not(.active):hover {
  background-color: transparent;
  color: var(--primary-color);
  border-bottom: 4px solid var(--primary-color);
  border-top-color: transparent;
  border-right-color: transparent;
  border-left-color: transparent;
}
::v-deep .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent !important;
  color: var(--primary-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
}
.button-extra {
  width: 250px !important;
  height: 35px;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
}
.button-extra-fill {
  width: 120px !important;
  height: 35px;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
}

.link-btn {
  color: var(--primary-color);
}
.input-group > .input-group-append:not(:last-child) > .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.header-filter {
  background-color: var(--theme-secondary-color);
  padding: 15px 0;
}
.text-filter {
  font-weight: 600;
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.btn-clear {
  background-color: transparent;
  border: none;
}
.text-under {
  text-decoration: underline;
}
::v-deep .rounded-pill {
  border-radius: 0px !important;
}
::v-deep .btn-main,
.btn-mains button {
  width: 100% !important;
  background-color: var(--primary-color) !important;
  border: 1px solid var(--primary-color);
}
.form-control {
  height: 100%;
}
.btn-link:disabled {
  color: #8a93a2 !important;
}
</style>
